import { i18n } from '../lang';

interface TelegramUserData {
  user: any;
  hash: string | null;
  initDataString: string;
}

export function getTelegramUserData(): TelegramUserData | undefined {
  try {
    const initDataString = window.Telegram?.WebApp?.initData;

    if (!initDataString) {
      const errorMessage = i18n.t('ERROR.TELEGRAM.INIT_DATA_STRING');

      throw new Error(errorMessage);
    }

    const params = new URLSearchParams(initDataString);

    const userString = params.get('user');
    const hash = params.get('hash');

    if (!userString) {
      const errorMessage = i18n.t('ERROR.TELEGRAM.HASH');

      throw new Error(errorMessage);
    }

    if (!hash) {
      const errorMessage = i18n.t('ERROR.TELEGRAM.HASH');

      throw new Error(errorMessage);
    }

    let user;
    try {
      user = JSON.parse(decodeURIComponent(userString));

      if (!user.username) {
        const errorMessage = i18n.t('ERROR.TELEGRAM.USER_NAME');

        throw new Error(errorMessage);
      }

      if (!user.id || user.id === 0 || user.id === '') {
        const errorMessage = i18n.t('ERROR.TELEGRAM.ID');

        throw new Error(errorMessage);
      }

      const errorMessage = i18n.t('ERROR.TELEGRAM.FIRST_NAME');
      if (!user.first_name) {
        throw new Error(errorMessage);
      }
    } catch (error) {
      const errorMessage = i18n.t('ERROR.TELEGRAM.PARSE_DATA');

      throw new Error(errorMessage);
    }

    return { user, hash, initDataString };
  } catch (error: any) {
    console.error(error.message);
    alert(error.message);
  }
}
