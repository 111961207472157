import {
  EVENT_CATEGORY_VIEW,
  EVENT_ITEM_DATA_RAW_INTER,
  EVENT_STATUS_VIEW,
} from './../event-list/constant';
import { EVENT_ITEM_DATA_INTER, EVENT_TAG_VIEW } from '../event-list/constant';
import { i18n } from '../../lib/lang';
import { LANG_STATUS_VIEW } from '../../lib/lang/value';

export const convert = (data: EVENT_ITEM_DATA_INTER) => ({
  ...data,
  category: data.category?.[0]?.value ?? null,
  tag: data.tag?.[0]?.value ?? null,
  status: data.status[0].value,
  languageCode: data.languageCode?.[0]?.value,
});

export const convertPreFetch = (data: EVENT_ITEM_DATA_RAW_INTER) => ({
  ...data,
  category: data?.category && [
    {
      value: data.category,
      label: i18n.t(EVENT_CATEGORY_VIEW[data.category]),
    },
  ],

  tag: data?.tag && [
    {
      value: data.tag,
      label: i18n.t(EVENT_TAG_VIEW[data.tag]),
    },
  ],
  status: [
    {
      value: data.status,
      label: i18n.t(EVENT_STATUS_VIEW[data.status]),
    },
  ],
  languageCode: [
    {
      value: data.languageCode,
      label: LANG_STATUS_VIEW[data.languageCode],
    },
  ],
});
