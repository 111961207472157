import React, { useState } from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { GridElem } from '../../common/grid';
import { Card } from './frame/card';
import { DATA_INTER, EVENT_ITEM_DATA_RAW_INTER } from '../event-list/constant';
import { EmptyListElem } from '../../common/empty-list-elem';
import { COLOR_ENUM } from '../../theme/color';
import { SkeletonDataElem } from '../../common/skeleton-data';
import { AdminEventFilterContainer } from '../admin-event-filter';

export const Component: React.FC<{
  data?: DATA_INTER;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setPayloadHandler: (key: string, value: string) => void;
}> = ({
  data,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  setPayloadHandler,
}) => {
  const [openCardId, setOpenCardId] = useState<string | null>(null);

  const handleCardClick = (id: string) => {
    setOpenCardId((prevOpenCardId) => (prevOpenCardId === id ? null : id));
  };

  return (
    <Container>
      <GridElem spacing={4}>
        {isLoading && <SkeletonDataElem />}
        {isError && <AlertActionElem tid={`ERROR.${errorMessage}`} />}
        {data?.isEmpty && !isLoading && (
          <EmptyListElem
            titleTid="EVENT.LIST.IS_EMPTY_TITLE"
            descriptionTid="EVENT.LIST.IS_EMPTY_DESCRIPTION"
          />
        )}
        {!isLoading && <Divider />}
        <AdminEventFilterContainer setPayload={setPayloadHandler} />
        {isSuccess &&
          data &&
          !data.isEmpty &&
          data?.list.map((item: EVENT_ITEM_DATA_RAW_INTER) => (
            <>
              <React.Fragment key={item.id}>
                <Card
                  isOpen={openCardId === item.id}
                  onClick={() => handleCardClick(item.id)}
                  data={item}
                />
              </React.Fragment>
              <Divider />
            </>
          ))}
      </GridElem>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Divider = styled.div`
  background-color: ${({ theme }) => theme[COLOR_ENUM.DIVIDER]};
  height: 1px;
  width: 100%;
`;
