import { LANGUAGE_TAG_ENUM } from '../../lib/lang/value';
import { SELECT_INTER } from '../event-create/constant';

export const MODULE_NAME = 'EVENT_ITEM_CLONE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  LINK = 'link',
  IMG_URL = 'imgUrl',
  TITLE = 'title',
  DESCRIPTION = 'description',
  TAG = 'tag',
  CATEGORY = 'category',
  ORDER = 'order',
  STATUS = 'status',
  UTM = 'utmParam',
  LANGUAGE_CODE = 'languageCode',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.TITLE]: string;
  [FORM_VALUE_ENUM.LINK]: string;
  [FORM_VALUE_ENUM.IMG_URL]: string;
  [FORM_VALUE_ENUM.DESCRIPTION]: string;
  [FORM_VALUE_ENUM.TAG]: SELECT_INTER[];
  [FORM_VALUE_ENUM.CATEGORY]: SELECT_INTER[];
  [FORM_VALUE_ENUM.ORDER]: number;
  [FORM_VALUE_ENUM.STATUS]: SELECT_INTER[];
  [FORM_VALUE_ENUM.UTM]: string;
  [FORM_VALUE_ENUM.LANGUAGE_CODE]: LANGUAGE_TAG_ENUM;
}

export const API = {
  UPDATE: {
    TYPE: 'PATCH',
    URL: (id?: string) => `event/update/${id}`,
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
