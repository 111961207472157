import React from 'react';
import { FormikValues } from 'formik';
import styled from 'styled-components';

import { ButtonElem } from '../../common/button';
import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { FormElem } from '../../common/form';
import { FieldTextElem } from '../../common/field-text';
import { FieldTextAreaElem } from '../../common/field-text-area';
import { SelectElem } from '../../common/select';
import { Spacing } from '../../theme';
import { CATEGORY_LIST, TAG_LIST, STATUS_LIST } from '../event-create/constant';
import { FORM_VALUE_ENUM } from './constant';
import { LANGUAGE_LIST } from '../../lib/lang/value';
import { EventItemCloneContainer } from '../event-item-clone';
import { EVENT_ITEM_DATA_RAW_INTER } from '../event-list/constant';
import { ModalElem } from '../../common/modal';

export const Component: React.FC<{
  isSubmitDisabled: Function;
  isError?: boolean;
  isSuccess?: boolean;
  errorMessage?: string;
  handleUpdate: Function;
  isLoading?: boolean;
  formik: FormikValues;
  getFieldValue: Function;
  getFieldError: Function;
  isFieldError: Function;
  setFieldValue: Function;
  setValue: Function;
  data: EVENT_ITEM_DATA_RAW_INTER;
}> = ({
  isSubmitDisabled,
  isError,
  errorMessage,
  isSuccess,
  handleUpdate,
  isLoading,
  formik,
  getFieldError,
  isFieldError,
  setFieldValue,
  getFieldValue,
  setValue,
  data,
}) => {
  const [isModal, setIsModal] = React.useState(false);

  return (
    <FormElem onSubmit={() => {}}>
      <Container>
        <ExpandContainer>
          <SaveContainer>
            <FieldTextElem
              placeholder="EVENT.CREATE.IMG_URL"
              name={FORM_VALUE_ENUM.IMG_URL}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.IMG_URL)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.IMG_URL)}
              error={isFieldError(FORM_VALUE_ENUM.IMG_URL)}
            />
            <ButtonElem
              onClick={handleUpdate}
              disabled={isSubmitDisabled()}
              type="submit"
              tid="EVENT.CREATE.SAVE"
            />
          </SaveContainer>
          <FieldTextElem
            placeholder="EVENT.CREATE.TITLE"
            name={FORM_VALUE_ENUM.TITLE}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={getFieldValue(FORM_VALUE_ENUM.TITLE)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.TITLE)}
            error={isFieldError(FORM_VALUE_ENUM.TITLE)}
          />
          <SelectElem
            dynamic
            placeholder="EVENT.CREATE.LANGUAGE_CODE"
            options={LANGUAGE_LIST}
            name={FORM_VALUE_ENUM.LANGUAGE_CODE}
            onChange={setFieldValue}
            errorMessage={getFieldError(FORM_VALUE_ENUM.LANGUAGE_CODE)}
            error={isFieldError(FORM_VALUE_ENUM.LANGUAGE_CODE)}
            defaultValue={getFieldValue(FORM_VALUE_ENUM.LANGUAGE_CODE)}
          />
          <DoubleContainer>
            <FieldTextElem
              placeholder="EVENT.CREATE.UTM"
              name={FORM_VALUE_ENUM.UTM}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.UTM)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.UTM)}
              error={isFieldError(FORM_VALUE_ENUM.UTM)}
            />
            <FieldTextElem
              placeholder="EVENT.CREATE.LINK"
              name={FORM_VALUE_ENUM.LINK}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.LINK)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.LINK)}
              error={isFieldError(FORM_VALUE_ENUM.LINK)}
            />
          </DoubleContainer>
          <DoubleContainer>
            <SelectElem
              isClearable
              placeholder="EVENT.CREATE.CATEGORY"
              options={CATEGORY_LIST}
              name={FORM_VALUE_ENUM.CATEGORY}
              onChange={setFieldValue}
              errorMessage={getFieldError(FORM_VALUE_ENUM.CATEGORY)}
              error={isFieldError(FORM_VALUE_ENUM.CATEGORY)}
              defaultValue={getFieldValue(FORM_VALUE_ENUM.CATEGORY)}
            />
            <SelectElem
              isClearable
              placeholder="EVENT.CREATE.TAG"
              options={TAG_LIST}
              name={FORM_VALUE_ENUM.TAG}
              onChange={setFieldValue}
              errorMessage={getFieldError(FORM_VALUE_ENUM.TAG)}
              error={isFieldError(FORM_VALUE_ENUM.TAG)}
              defaultValue={getFieldValue(FORM_VALUE_ENUM.TAG)}
            />
          </DoubleContainer>
          <DoubleContainer>
            <SelectElem
              placeholder="EVENT.CREATE.STATUS"
              options={STATUS_LIST}
              name={FORM_VALUE_ENUM.STATUS}
              onChange={setFieldValue}
              errorMessage={getFieldError(FORM_VALUE_ENUM.STATUS)}
              error={isFieldError(FORM_VALUE_ENUM.STATUS)}
              defaultValue={getFieldValue(FORM_VALUE_ENUM.STATUS)}
            />
            <FieldTextElem
              placeholder="EVENT.CREATE.ORDER"
              name={FORM_VALUE_ENUM.ORDER}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.ORDER)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.ORDER)}
              error={isFieldError(FORM_VALUE_ENUM.ORDER)}
              type="number"
            />
          </DoubleContainer>

          <FieldTextAreaElem
            placeholder="EVENT.CREATE.DESCRIPTION"
            name={FORM_VALUE_ENUM.DESCRIPTION}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={getFieldValue(FORM_VALUE_ENUM.DESCRIPTION)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
            error={isFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
          />
          <ButtonElem
            onClick={() => {
              setIsModal(true);
            }}
            type="primary"
            tid="EVENT.CREATE.CLONE"
          />
          <ModalElem open={isModal} onClose={() => setIsModal(false)}>
            <EventItemCloneContainer data={data} />
          </ModalElem>
        </ExpandContainer>
      </Container>
      {isError && <AlertActionElem tid={`ERROR.${errorMessage}`} />}
      {isSuccess && <AlertActionElem type="success" tid="Success" />}
      {isLoading && <LoaderElem />}
    </FormElem>
  );
};

const DoubleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(2)};
`;

const SaveContainer = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;
  grid-gap: ${Spacing(2)};
`;

const ExpandContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
`;

const Container = styled.div`
  display: grid;
  width: 100%;
  grid-gap: ${Spacing(3)};
`;
