import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { MODULE_NAME } from './constant';
import { Component } from './component';
import { action } from './action';
import {
  ACTION_ERROR_INTER,
  ACTION_RESPONSE_INTER,
  DATA_INTER,
} from '../event-list/constant';

export const Container: React.FC<{}> = ({}) => {
  const [payload, setPayload] = useState<{
    lang: string;
    status: string;
  }>({
    lang: '',
    status: '',
  });

  const setPayloadHandler = (key: string, value: string) => {
    setPayload((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const preFetch = useQuery([MODULE_NAME, payload.lang, payload.status], () =>
    action(payload),
  );

  const isLoading = () => {
    if (preFetch.isLoading || preFetch.isFetching) {
      return true;
    }
  };

  const isSuccess = () => {
    if (preFetch.isSuccess && !preFetch.isFetching) {
      return true;
    }
  };

  const isError = () => {
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = preFetch.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const getData = (): DATA_INTER | undefined => {
    const data = preFetch.data as unknown as ACTION_RESPONSE_INTER;

    if (data) {
      return { ...data, isEmpty: data?.list?.length < 1 };
    }
  };

  return (
    <Component
      setPayloadHandler={setPayloadHandler}
      data={getData()}
      isLoading={isLoading()}
      isError={isError()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
    />
  );
};
