import React, { useEffect, useMemo, useState } from 'react';

import { Component } from './component';
import { EARNING_LIST_MODULE_NAME } from '../earning-admin-list';
import { useQuery, useQueryClient } from 'react-query';
import { EARNING_USER_LIST_MODULE_NAME } from '../earning-create/constant';
import { EVENT_LIST_MODULE_NAME } from '../event-list';
import { convertUser, convertEvent } from '../earning-create/convert';
import { ACTION_RESPONSE_INTER } from '../user-list/constant';
import { ACTION_RESPONSE_INTER as ACTION_RESPONSE_EVENT_INTER } from '../event-list/constant';
import { action as eventAction } from '../event-list/action';
import { getUserListShort } from '../../data/user/action';
import { USER_LIST_SHORT_MODULE_NAME } from '../../data/user/constant';

export const Container: React.FC<{
  setPayload: (key: string, value: string) => void;
}> = ({ setPayload }) => {
  return <Component setPayload={setPayload} />;
};
