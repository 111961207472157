import { EVENT_STATUS } from '../event-list/constant';

export const MODULE_NAME = 'ADMIN_EVENT_FILTER_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  STATUS = 'status',
  LANG = 'lang',
}

export const langOption = [
  {
    value: 'uk',
    label: 'UA',
  },

  {
    value: 'pl',
    label: 'PL',
  },

  {
    value: 'ru',
    label: 'RU',
  },
];

export const statusOption = [
  {
    value: EVENT_STATUS.ACTIVE,
    label: 'Active',
  },
  {
    value: EVENT_STATUS.DISABLED,
    label: 'Disabled',
  },
];
