import React from 'react';
import styled from 'styled-components';

import { FORM_VALUE_ENUM, langOption, statusOption } from './constant';
import { Spacing } from '../../theme';
import { SelectElem } from '../../common/select';
import { OPTION_ITEM_DATA } from '../../common/select/constant';

export const Component: React.FC<{
  setPayload: (key: string, value: string) => void;
}> = ({ setPayload }) => {
  return (
    <Double>
      <SelectElem
        isClearable
        dynamic
        filterOption={() => true}
        placeholder="EVENT.FILTER.STATUS"
        options={statusOption}
        name={FORM_VALUE_ENUM.STATUS}
        onChange={(name: string, value: OPTION_ITEM_DATA) =>
          setPayload(name, value?.value)
        }
      />
      <SelectElem
        isClearable
        dynamic
        filterOption={() => true}
        placeholder="EVENT.FILTER.LANG"
        options={langOption}
        name={FORM_VALUE_ENUM.LANG}
        onChange={(name: string, value: OPTION_ITEM_DATA) =>
          setPayload(name, value?.value)
        }
      />
    </Double>
  );
};

const Container = styled.div`
  display: grid;
  grid-gap: ${Spacing(3)};
  margin-bottom: ${Spacing(2)};
`;

const Double = styled.div`
  margin-top: ${Spacing(1)};
  display: flex;
  gap: ${Spacing(2)};
  width: 100%;
`;
