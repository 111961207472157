import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const action = ({ search, lang, status }: any) => {
  let params = '?';

  if (search) {
    params += `name=${search}`;
  }

  if (lang) {
    params += `&lang=${lang}`;
  }

  if (status) {
    params += `&status=${status}`;
  }

  return HttpRequest({
    method: API.TYPE,
    url: API.URL(params),
  });
};
